import React, { useState, useRef, useEffect, MutableRefObject } from 'react';
import { Section, Row, Col, Pagination, Icon } from 'react-materialize';
import { setSourceMapRange } from 'typescript';
import { FeatureCard } from '../featuredCard';
import { data } from './data';

import './styles.scss';

export const Experimental = () => {
  const container = useRef() as MutableRefObject<HTMLDivElement>;
  const [currentWidth, setCurrentWidth] = useState(0);

  const updateDimensions = () => {
    setCurrentWidth(container.current ? container.current.offsetWidth : 0);
  };

  useEffect(updateDimensions, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
  }, []);

  let perPage = currentWidth > 960 ? 4 : 3;

  const pages = Math.ceil(data.length / perPage);

  const [prevPage, setPrevPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const setPage = (p: number) => {
    setCurrentPage(p);
    setPrevPage(currentPage);
  };

  const featuredCards = data.map((item, i) => {
    const lowEnd = (currentPage - 1) * perPage;
    const highEnd = currentPage * perPage;

    const showMe = i >= lowEnd && i < highEnd;
    const fade = prevPage > currentPage ? 'fade-in-left' : 'fade-in-right';

    return showMe ? (
      <Col
        key={`exp_${i}`}
        xl={3}
        l={4}
        s={12}
        style={{
          minHeight: '500px',
        }}
      >
        <FeatureCard data={item} className={fade} />
      </Col>
    ) : null;
  });

  const pagProps = {
    className: 'pag-green',
    onSelect: (p: number) => {
      setPage(p);
    },
  };

  const paginationNav = (
    <Pagination
      {...pagProps}
      activePage={1}
      items={pages}
      leftBtn={<Icon>chevron_left</Icon>}
      rightBtn={<Icon>chevron_right</Icon>}
    />
  );

  return (
    <Section id='experimental' className='section scrollspy background-svgcog'>
      <div style={{ backgroundColor: '#00000033', paddingTop: '20px' }}>
        <div ref={container} className='container'>
          <h4 style={{ marginLeft: '10px', marginBottom: '10px' }}>
            Experimental
          </h4>
          <Row>{featuredCards}</Row>
          <Row style={{ marginTop: '-20px' }}>{paginationNav}</Row>
        </div>
      </div>
    </Section>
  );
};
