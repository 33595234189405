import React from 'react';
import { Title } from '../title';
import { Featured } from '../featured';
import { Experimental } from '../experimental';
import { Paraquote } from '../paraquote';
import { Work } from '../work';
import { History } from '../history';
//
import bg1 from '../../img/backgrounds/jrvclassics_tile_top.jpg';
import bg2 from '../../img/backgrounds/jrvclassics_tile_mid.jpg';
//
export const BodyComponent = () => {
  return (
    <div className='Body'>
      <Title />
      <Featured />
      <Experimental />
      <Paraquote
        height={400}
        textOffset={100}
        backgroundImage={bg1}
        quoteText='It is a happy talent to know how to play.'
        quoteAuthor='Ralph Waldo Emerson'
      />
      <Work />
      <Paraquote
        backgroundImage={bg2}
        quoteText='The creation of something new is not accomplished by the intellect but by the play instinct.'
        quoteAuthor='Carl Jung'
      />
      <History />
    </div>
  );
};
