import React, { useState } from 'react';
import { Section, Button } from 'react-materialize';
import { IconGrid } from '../iconGrid';
//
import spaceInvaderImg from '../../img/backgrounds/si_50px.gif';

export const Work = () => {
  const [isMoreContent, setIsMoreContent] = useState(false);

  const toggleContent = (forceTo?: boolean) =>
    setIsMoreContent(forceTo !== undefined ? forceTo : !isMoreContent);

  const moreInfoButton = (
    <div id='about_work_more_btn_container' className='col s12 l5'>
      <Button
        waves='light'
        onClick={() => {
          toggleContent(true);
        }}
      >
        TELL ME MORE
      </Button>
    </div>
  );

  const lessInfoButton = (
    <div id='about_work_less_btn' style={{ marginTop: '10px' }}>
      <Button
        waves='light'
        onClick={() => {
          toggleContent(false);
        }}
      >
        WOAH! TOO MUCH INFORMATION
      </Button>
    </div>
  );

  const blockWhat = (
    <div id='about_work_left'>
      <p>
        Gameplay has long been a popular pursuit, from the simplest moves of Go,
        first played in China 3,000 years ago, to the massively multiplayer
        online games of today. In 2020, digital games generated $126.6 billion
        in sales globally (according to Superdata, a Nielsen company), and their
        popularity is driving the adoption of elements of gamification in many
        other areas such as business and education.
      </p>
      <p className=' hide-on-small-only'>
        Another primary driver is the rapid uptake of social media, now used by
        over 3.6 billion (according to Statista, 2020) people, where reward and
        status elements are embedded in implicit and explicit forms in people’s
        interactions in their engagement in online communities. Game elements
        and competition are interspersed throughout the platforms that have made
        social networks like Facebook, Twitter, and Instagram so popular.
      </p>
      <p>
        According to the Pew Center for Research, “Serious gaming simulations
        and game mechanics such as leaderboards, achievements, and skill-based
        learning are becoming embedded in day-to-day business processes, driving
        adoption, performance, and engagement.” Elements of game mechanics are
        being employed nowadays in training, marketing, education, and wellness
        initiatives.<sup>1</sup>
      </p>
    </div>
  );

  const blockGamification = (
    <div id='about_work_right'>
      <p>
        According to a study conducted by the University of Colorado on the
        impact of simulations and games in adult learners, participants in
        gamified eLearning experiences scored
      </p>
      <ul>
        <li>
          &nbsp;&nbsp;&nbsp;&bull; 14% higher in skill-based-knowledge
          assessments,
        </li>
        <li>
          &nbsp;&nbsp;&nbsp;&bull; 11% higher in terms of factual-knowldege
        </li>
        <li>
          &nbsp;&nbsp;&nbsp;&bull; 9% increase in retention rate.
          <sup>5</sup>
        </li>
      </ul>
      <div id='si2' className='spaceinvader'>
        <img
          alt='Space Invader'
          className='spaceinvaderimg'
          src={spaceInvaderImg}
        />
      </div>
      <span className=' hide-on-small-only'>
        <p>
          Beyond learning, the global gamification market was expected to grow
          from USD 1.65 billion in 2015 to USD 11.10 billion by 2020. While this
          growth estimate was missed, the actual five year increase to 9.1
          billion in 2020 is significant.{' '}
        </p>
        <p>
          Currently, the gamification market size is projected to grow from USD
          9.1 billion in 2020 to USD 30.7 billion by 2025, at a Compound Annual
          Growth Rate (CAGR) of 27.4% during the forecast period. The major
          factors driving the growth of the gamification market include rewards
          and recognition to employees over performance to boost employee
          engagement, provision of lucrative offers to the customers and
          consumers, and gamification yielding higher Return on Investment
          (RoI).<sup>6</sup>
        </p>
      </span>
    </div>
  );

  const blockSources = (
    <p>
      Sources
      <br />
      <a
        href='https://www.pewinternet.org/2012/05/18/the-future-of-gamification/'
        target='_blank'
        rel='noreferrer'
        className='link'
      >
        <sup>1</sup> The Future of Gamification, 2012{' '}
        <i className='icon-link-ext'></i>
      </a>
      <br />
      <a
        href='https://elearningindustry.com/top-gamification-statistics-and-facts-for-2015'
        target='_blank'
        rel='noreferrer'
        className='link'
      >
        <sup>2</sup> Top Gamification Statistics, 2015{' '}
        <i className='icon-link-ext'></i>
      </a>
      <br />
      <span className=' hide-on-small-only'>
        <a
          href='https://www.marketsandmarkets.com/Market-Reports/gamification-market-991.html'
          target='_blank'
          rel='noreferrer'
          className='link'
        >
          <sup>4</sup> Gamification Market, 2019{' '}
          <i className='icon-link-ext'></i>
        </a>
      </span>
      <br />
      <span className=' hide-on-small-only'>
        <a
          href='https://www.researchgate.net/publication/229876609_A_meta-analytic_examination_of_the_instructional_effectiveness_of_computer-based_simulation_games'
          target='_blank'
          rel='noreferrer'
          className='link'
        >
          <sup>5</sup> A meta-analytic examination of the instructional
          effectiveness of computer-based simulation game, 2011{' '}
          <i className='icon-link-ext'></i>
        </a>
      </span>
      <br />
      <span className=' hide-on-small-only'>
        <a
          href='https://www.imarcgroup.com/game-based-learning-market'
          target='_blank'
          rel='noreferrer'
          className='link'
        >
          <sup>6</sup> Game-based Learning Market, 2019{' '}
          <i className='icon-link-ext'></i>
        </a>
      </span>
    </p>
  );

  const blockClients = (
    <div className='col s12 l7 content-text'>
      <h5>
        <b>Partial Client List</b>
      </h5>
      <p>
        Here are some of the clients we have worked with:{' '}
        <span className='jrvblue-text'>&bull;</span> American Express{' '}
        <span className='jrvblue-text'>&bull;</span> The Buffalo News{' '}
        <span className='jrvblue-text'>&bull;</span> Comedy Central{' '}
        <span className='jrvblue-text'>&bull;</span> Crayola{' '}
        <span className='jrvblue-text'>&bull;</span> Fisher-Price{' '}
        <span className='jrvblue-text'>&bull;</span> Houghton Mifflin Harcourt{' '}
        <span className='jrvblue-text'>&bull;</span> Independent Health{' '}
        <span className='jrvblue-text'>&bull;</span> InStyle Magazine{' '}
        <span className='jrvblue-text'>&bull;</span> Ripley Publishing{' '}
        <span className='jrvblue-text'>&bull;</span> Roadrunner Records{' '}
        <span className='jrvblue-text'>&bull;</span> SAP Global Marketing{' '}
        <span className='jrvblue-text'>&bull;</span> TIME Inc.{' '}
        <span className='jrvblue-text'>&bull;</span> Unilever Brands{' '}
        <span className='jrvblue-text'>&bull;</span> Xerox{' '}
        <span className='jrvblue-text'>&bull;</span>
      </p>
      <div
        id='si3'
        className='spaceinvader'
        style={{
          marginTop: '10px',
        }}
      >
        <img
          alt='Space Invader'
          className='spaceinvaderimg'
          src={spaceInvaderImg}
        />
      </div>
    </div>
  );

  const blockMoreClients = (
    <div id='about_work_clients' className='row'>
      <div className='col s12 content-text'>
        <h5>
          <b>Partial Client List</b>
        </h5>
        <p>
          Here are some of the agencies and brands have benefited directly from
          our work.
        </p>
        <p>
          4KIDS Productions, Ambient Blue, Arnold Worldwide, Artists In Buffalo,
          The Buffalo News & Buffalo.com,
          <b>Crayola</b>, Corus Interactive, The Cooper Group, Creata,
          Fisher-Price, <b>Houghton Mifflin Harcourt</b>, Independent Health,
          Lunchbox, MarketSource MS3, Montreal Canadiens, New Era Cap, Paddock
          Chevrolet, <b>Ripley Publishing</b>, Roadrunner Records, SAP Global
          Marketing, SAP LABS, Selatra Games, Silo Design, Simmulation
          Curriculum, Smith Brothers, <b>TIME Inc.</b>, Time Magazine, Real
          Simple Magazine, InStyle Magazine, People Magazine,{' '}
          <b>Life Magazine</b>.
        </p>

        <p>
          The following clients were serviced indirectly, through other agencies
          and studios.
        </p>
        <p>
          American Express, AT&T, <b>Comedy Central</b>, Delaware North
          Companies, <b>Fisher-Price</b>, GlaxoSmithKline, GlobalSpec,{' '}
          <b>IBM</b>, Kellogg's, Mattel, <b>Microsoft</b>, MTV, NCAA,{' '}
          <b>Ripley Entertainment</b>, Roache Pharmaceutica, Schering Plough,{' '}
          <b>Samsung</b>, Skinny Cow, Sony Pictures, Unilever, Xerox.
        </p>

        {isMoreContent && lessInfoButton}
      </div>
    </div>
  );

  return (
    <Section
      id='work'
      className='section scrollspy background-svgcog-lite'
      style={{
        padding: '25px',
        paddingBottom: '25px',
        backgroundColor: '#7b301b',
      }}
    >
      <div className='section white'>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h1
                className='subTitle'
                style={{ marginTop: '25px', fontWeight: 700 }}
              >
                THE WORK WE DO
              </h1>
              <h2 className='subTitle' style={{ marginTop: '-25px' }}>
                Gamification, Simulation and Serious&nbsp;Games
              </h2>
            </div>
          </div>
        </div>
        <div className='center'>
          <div className='row'>
            <IconGrid />
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div
              className='col s12 content-text center'
              style={{ margin: '10px' }}
            >
              <h5>
                <em>
                  With an eye for storytelling and the user experience, we pride
                  ourselves on being able to develop a game to enhance
                  engagement with any brand, product, service, or social issue.
                </em>
              </h5>
            </div>
          </div>
          <div className='row'>
            <div className='col s12 l7 content-text'>
              <h5>
                <strong>This is "gamification."</strong>
              </h5>
              <p>
                The integration or application of games and game-related
                concepts to everyday tasks such as learning, wellness,
                productivity and business activities. <br />
                <h6>It's what we do.</h6>
              </p>
              {isMoreContent && blockWhat}
              {isMoreContent && blockSources}
            </div>

            <div className='col s12 l5 content-text'>
              <h5>
                <b>Gamification Statistics</b>
              </h5>
              <p>
                The total global revenue in the learning games market in 2012
                was $3.9 Million<sup>1</sup> and grew to $2.6 Billion in 2019
                <sup>2</sup>, making it one of the fastest growing trends in
                education.
              </p>
              {isMoreContent && blockGamification}
            </div>
          </div>
          <div id='about_work_clientsmini' className='row'>
            {!isMoreContent && blockClients}
            {!isMoreContent && moreInfoButton}
          </div>

          {isMoreContent && blockMoreClients}
        </div>
      </div>
    </Section>
  );
};
