import React from 'react';
import { Navbar, NavItem, Icon } from 'react-materialize';
//
import './styles.scss';
//
import logo from '../../img/main-logo.png';

export const HeaderComponent = () => {
  return (
    <div className='Header'>
      <Navbar
        id='main-nav'
        fixed
        centerChildren
        alignLinks='right'
        menuIcon={<Icon>menu</Icon>}
        options={{}}
        brand={
          <a href='/' className='brand-logo'>
            <img
              alt='JRVisual Logo'
              src={logo}
              style={{ width: '85%', margin: '0 auto', paddingTop: '5px' }}
            />
          </a>
        }
        style={{ backgroundColor: '#FFF' }}
      >
        <div className='navItem'>
          <NavItem href='#play'>PLAY</NavItem>
          {/* className='active'*/}
        </div>
        <div className='navItem'>
          <NavItem href='#work'>WORK</NavItem>
        </div>
        {/* <div className='navItem'>
          <NavItem href='#history'>HISTORY</NavItem>
        </div> */}
        <div className='navItem'>
          <NavItem href='#connect'>CONNECT</NavItem>
        </div>
      </Navbar>
    </div>
  );
};
