import React from 'react';

import feat_donvkim from '../../img/featured/feat_donvkim.jpg';
import feat_obts from '../../img/featured/feat_obts.jpg';
import feat_cloudfall from '../../img/featured/feat_cloudfall.jpg';
import feat_iamnoself from '../../img/featured/feat_iamnoself.jpg';
import feat_spacejunk from '../../img/featured/feat_spacejunk.jpg';
import feat_wcg from '../../img/featured/feat_whatscat.jpg';
import feat_r3f from '../../img/featured/feat_r3f.jpg';
import feat_aprlive from '../../img/featured/feat_aprlive.jpg';
import feat_ld46 from '../../img/featured/feat_ld46.jpg';
import feat_dontshoot from '../../img/featured/feat_dontshoot.jpg';

export const data = [
  {
    title: 'O.B.T.S.',
    image: feat_obts,
    teaser: 'One-bit Type Shooter. Your keyboard is your weapon.',
    full: (
      <div>
        <p>
          Mavis Beacon eat your heart out. This is not your parent's typing
          tutor.
        </p>
        <p>
          The WASDA System is under invasion by the Evil Empire and you alone
          can defend it in this typing game meets bullet hell shooter mashup.
        </p>
        <p></p>
      </div>
    ),
    buttontext: 'TYPE',
    buttonlink: 'games/obts/next/',
  },

  {
    title: 'Ludum Dare 46',
    image: feat_ld46,
    teaser: 'Keep Ye Alive, a 48 hour game jam game.',
    full: (
      <div>
        <p>
          <em>GitHub Repository Available</em>
        </p>
        <p>
          Ludum Dare is a yearly game jame. Participants have 48 hours to
          develop a game around a croudsourced concept which is unveiled when
          the clock starts ticking.
        </p>
        <p>
          While the version of the game line to here has the benefit of another
          50 or so hours of work done after the original game jam version, the
          core functionality and concept were concieved in the crucible of Ludum
          Dare.
        </p>
        <p>
          The game is an "endless runner with roguelike elements" and a twist.
          The game character is in control of their own destiny and you, the
          player, have to keep him alive by picking up coins and using them to
          purchase potions for the hero.
        </p>
        <p>
          In the spirit of Ludum Dare, the source code for this game is posted
          here: <br />-{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://github.com/jrod-disco/ld46-keepalive'
          >
            {' '}
            jrod-disco/ld46-keepalive
          </a>
        </p>
      </div>
    ),
    buttontext: 'KEEP YE ALIVE',
    buttonlink: 'games/ld46-latest/',
  },

  {
    title: 'R3F-POC',
    image: feat_r3f,
    teaser: 'VR-friendly experiments with react-three-fiber.',
    full: (
      <div>
        <p>
          <em>GitHub Repository Available</em>
        </p>
        <p>
          The react-three-fiber library facilitates the creation of ThreeJS
          content within a React application environment. These experiments
          scracth the surface of what is possible with this library. Please note
          that these examples are not optimized and some may take a few minutes
          to load depending on your internet connection.
        </p>
        <p>
          My favorite experiment, to date, TURRELL CUBE is an ode to artist
          James Turrell. Specifically the installation “Catso, Red” at the
          Mattress Factory in Pittsburgh, PA. This was the first work by Turrell
          that I experienced.
        </p>
        <p>
          The related repositories are publicly available here: <br />-{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://github.com/jrod-disco/r3f-poc'
          >
            {' '}
            jrod-disco/r3f-poc
          </a>
          <br />-{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://github.com/pmndrs/react-three-fiber'
          >
            {' '}
            pmndrs/react-three-fiber
          </a>
          <br />
        </p>
      </div>
    ),
    buttontext: 'VIEW TURRELL CUBE',
    buttonlink: '/labs/r3f/experiments/cube_turrel',
    buttontarget: '',
  },

  {
    title: 'Don Vs. Kim',
    image: feat_donvkim,
    teaser: 'A childish two-player battle for global domination.',
    full: (
      <div>
        <p>
          Inspired by the foreign policy of the 45th U.S. presidential
          administration, this editorial-cartoon-gone-interactive pits two
          unbalanced world leaders in a dangerous nuclear food fight.
        </p>
        <p>
          The twist in this &#8220;news game&#8221; is that both players need to
          share the same touch-screen device while playing. What usually starts
          as a civil contest soon devolves into finger pointing and name
          calling. Sad.
        </p>
      </div>
    ),
    buttontext: 'LAUNCH',
    buttonlink: 'games/donvkim/',
  },

  {
    title: "Don't Shoot!",
    image: feat_dontshoot,
    teaser: 'A serious game inspired by current events.',
    full: (
      <div>
        <p>
          Another editorial-cartoon-gone-interactive torn from the headlines of
          2014, and could, sadly. just as well be today.
        </p>
        <p>
          Referencing the unecessary death of Michael Brown in Furguson
          Missouri. Don't Shoot, mimics the classic Nintendo game Hogan's Alley,
          offering the player different possible targets to click and shoot at.
          However, to score the near-impossible score of 10,000 points, the
          player must show great patience and restraint.
        </p>
      </div>
    ),
    buttontext: 'GO',
    buttonlink: 'games/dontshoot/',
  },

  {
    title: 'I Am No-Self',
    image: feat_iamnoself,
    teaser: 'Twitter art inspired by the principles of egolesness.',
    full: (
      <div>
        <p>
          Back in 2010 I had the internet ask itself, “Who am I?” – “I AM, No
          Self” was the response. A digital art project which looks at identity
          and egolessness through the lens of social media, the project began as
          a personal contemplation. Born of my meditation practice it was shown
          for the first time in March of 2010 at the SP@CE224 Gallery in
          Buffalo, New York. The piece was part of a show themed around the
          concept of identity.
        </p>
        <p>
          You can read the full artist statement and see photos from the gallery
          show at{' '}
          <a
            target='_blank'
            rel='noreferrer'
            href='https://blog.jrvisuals.com/posts/i-am-no-self/'
          >
            {' '}
            blog.jrvisuals.com
          </a>
        </p>
      </div>
    ),
    buttontext: 'REFLECT',
    buttonlink: 'https://www.iamnoself.me',
    buttontarget: '_blank',
  },

  {
    title: 'Space Junk',
    image: feat_spacejunk,
    teaser: 'Discover debris in a serious game inspired by NPR.',
    full: (
      <div>
        <p>
          Powered by HTML5 Canvas this action puzzler challenges you to populate
          Earth's low orbit. This game keeps players entertained at the desktop,
          or on the go, with fully responsive mobile support.
        </p>
        <p>
          <a
            href='http://www.npr.org/templates/story/story.php?storyId=127263485'
            target='_blank'
            rel='noreferrer'
          >
            An NPR news story
          </a>{' '}
          shed light on the hundreds of thousands of pieces of space junk
          orbiting our planet and the dangers this debris poses to our military
          and communications satellites.
        </p>
      </div>
    ),
    buttontext: 'LAUNCH MISSION',
    buttonlink: 'games/spacejunk/',
    buttontarget: '',
  },

  {
    title: 'A.P.R. Live',
    image: feat_aprlive,
    teaser: 'Burchfield Penney Art Center hosts musical collaboration.',
    full: (
      <div>
        <p>
          In December of 2017 I was honored to collaborate with Mike Sentman at
          the Burchfield Penney Art Center, in Buffalo, NY. Mike graciously
          played a set of music improvised along to the audience playing a "Stay
          Gold" commemorative version of my game, A.P.R. (Abduct, Probe,
          Release).
        </p>
      </div>
    ),
    buttontext: 'YOUTUBE VIDEO',
    buttonlink: 'https://www.youtube.com/watch?v=DOlRF_duLq8',
    buttontarget: '_blank',
  },

  {
    title: 'Snoop’s Cloudfall',
    image: feat_cloudfall,
    teaser: 'Tilt your phone and fly high with a skydiving Snoop.',
    full: (
      <div>
        <p>
          The bad news is that you&#x27;re falling through the sky - the good
          news is there is no ground... but lots of loot to collect! Just
          another game about rap O.G. skydiving for his medicinal breakfast
          cereal.
        </p>
        <p>
          This fun mini game features and original chiptune score and tilt
          controls on any phone or tablet that supports it. Don't worry, you can
          still play it on your laptop using the keyboard.
        </p>
      </div>
    ),
    buttontext: 'DROP IN',
    buttonlink: 'games/cloudfall/',
    buttontarget: '',
  },

  {
    title: "What's Cat Gets",
    image: feat_wcg,
    teaser: 'A "Doodle Game" about social media and cats.',
    full: (
      <div>
        <p>
          A mobile-first &quote;doodle game&quote; that puts the player in the
          paws of their pet.
        </p>
        <p>
          Sure, everyone likes cats on social media but did you ever think about
          how <em>your</em> cat feels about the situation.
        </p>
      </div>
    ),
    buttontext: 'SWIPE',
    buttonlink: 'games/whatscat/',
    buttontarget: '',
  },
];
