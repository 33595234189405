import React from 'react';
import { Parallax } from 'react-materialize';

interface Props {
  backgroundImage: string;
  quoteText: string;
  quoteAuthor: string;
  height?: number;
  textOffset?: number;
}

export const Paraquote = (props: Props) => {
  const { backgroundImage, quoteText, quoteAuthor, height, textOffset } = props;

  const divHeight = Math.min(height ? height : 500, 500);
  const topMargin = textOffset ? textOffset : 100;

  return (
    <div
      className='parallax-container hide-on-small-only'
      style={{
        height: `${divHeight}px`,
      }}
    >
      <Parallax
        image={<img alt='' src={backgroundImage} />}
        options={{
          responsiveThreshold: 0,
        }}
      >
        <div className='container center'>
          <h2
            className='quote white-text'
            style={{ marginTop: `${topMargin}px` }}
          >
            {quoteText}
          </h2>
          <h2 className='quoteattrib white-text' style={{ fontSize: '2em' }}>
            &mdash; {quoteAuthor}
          </h2>
        </div>
      </Parallax>
    </div>
  );
};
