import React from 'react';
import feat_joshallenrunner from '../../img/featured/feat_joshallenrunner.jpg';
import feat_caixa from '../../img/featured/feat_caixa.jpg';
import feat_dpfootball from '../../img/featured/feat_dpfootball.jpg';
import feat_powerstrike from '../../img/featured/feat_powerstrike.jpg';
import feat_watchdogs from '../../img/featured/feat_watchdogs.jpg';

export const data = [
  {
    isfeatured: '1',
    title: 'Josh Allen Runner',
    image: feat_joshallenrunner,
    teaser: 'An 8-bit tribute to Buffalo Bills number 17.',
    full: (
      <div>
        <p>Josh Allen of the Buffalo Bills is known for his heroic leaps.</p>
        <p>
          Jump like Josh in this fan-made web game and see how you do when
          you're in control of the play!
        </p>
        <p>
          As seen on local news station{' '}
          <a
            href='https://www.wgrz.com/video/entertainment/television/programs/most-buffalo/jump-like-josh/71-93a52b63-df8b-47f3-b598-e08ee8c661b5'
            target='_blank'
            rel='noreferrer'
          >
            WGRZ
          </a>
          .
        </p>
      </div>
    ),
    buttontext: 'JUMP LIKE JOSH',
    buttonlink: 'https://joshallen-runner.com/',
  },
  {
    isfeatured: '1',
    title: 'Match Caixa',
    image: feat_caixa,
    teaser: 'A deceptively simple color matching mobile game.',
    full: (
      <div>
        <p>
          Conceptualized nearly 20 years ago, Match Caixa is now in Early Access
          on the Google Play Store.
        </p>
        <p>
          This relaxing puzzler generates soothing tones as players tap and
          swipe their screen to match the target color.
        </p>
        <p>
          Unsurprisingly this game was originally developed in Flash and has
          been through many iterations before finally making it to mobile
          devices. The original version was featured in PRINT Magazine's 2005
          Interactive Anual and a finalist in the Flash Forward Festival the
          same year.
        </p>
      </div>
    ),
    buttontext: 'EARLY ACCESS',
    buttonlink:
      'https://play.google.com/store/apps/details?id=com.jrvisuallsllc.matchcaixa',
  },

  {
    isfeatured: '1',
    title: 'Dr. Pepper Pass',
    image: feat_dpfootball,
    teaser: 'Swiftly snap passes into the moving targets to score.',
    full: (
      <div>
        <p>
          Displayed on an oversized touch screen at promotional events this
          HTML5 sports favorite scored big with the college crowd. The $25,000
          sweepstake might have had something to do with it but so did the fast
          and addictive gameplay.
        </p>
        <p>
          Using the ultra-flexible PhaserJS framework we were able to create a
          game that worked not only on the big screen but on all screens.
        </p>
      </div>
    ),
    buttontext: 'HUT HUT HIKE',
    buttonlink: 'games/dpfootball/',
    buttontarget: '',
    isotag_1: 'html',
    isotag_2: '',
    isotag_3: 'play',
  },

  {
    isfeatured: '1',
    title: 'Power Strike',
    image: feat_powerstrike,
    teaser: 'Power Rangers blast away in this promotional game.',
    full: (
      <div>
        <p>
          This promotional game, for a set of Power Ranger Dino Charge toys,
          works even on older browsers with no support for modern features like
          HTML5 Canvas.
        </p>
        <p>
          Battle your way through three levels of power blasting to defeat the
          evil alien and his henchmen; performing power punches and sword
          strikes for bonus points.
        </p>
      </div>
    ),
    buttontext: 'PLAY NOW',
    buttonlink: 'games/dinocharge/',
    buttontarget: '',
    isotag_1: 'html',
    isotag_2: '',
    isotag_3: 'play',
  },

  {
    title: 'Stealth_Match',
    image: feat_watchdogs,
    teaser: 'Play the hacker in this cross-branded video game promo.',
    full: (
      <div>
        <p>
          Released in the spring of 2014, WATCH_DOGS is an open world
          action-adventure video game developed and published by{' '}
          <a href='http://www.Ubisoft.com/' target='_blank' rel='noreferrer'>
            Ubisoft
          </a>
          .{' '}
          <p>
            This mobile friendly, cross-branded game whet gamers&rsquo; appetite
            and offered them a sweepstakes as further incentive to beat the
            clock, break the code and enter the cyberpunk world of WATCH_DOGS.
          </p>
        </p>
      </div>
    ),
    buttontext: 'HACK THE CODE',
    buttonlink: 'games/stealthmatch/',
    buttontarget: '',
    isotag_1: 'html',
    isotag_2: '',
    isotag_3: 'play',
  },
];
