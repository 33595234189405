import React from 'react';
import './styles/main.scss';

import { HeaderComponent } from './components/header';
import { BodyComponent } from './components/body';
import { FooterComponent } from './components/footer';

function App() {
  return (
    <div className='App'>
      <HeaderComponent />
      <BodyComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
