import React from 'react';
import { Section } from 'react-materialize';
//
import './styles.scss';
//
import spaceInvaderImg from '../../img/backgrounds/si_50px.gif';

export const Title = () => {
  return (
    <Section id='play' className='section scrollspy background-svgcog'>
      <div className='container'>
        <div className='row center-align'>
          <div className='col s12 center-align'>
            <h1
              className='black-text'
              style={{ marginTop: '60px', fontWeight: 400, fontSize: '1.5rem' }}
            >
              <b>Games and playful experiences</b> for&nbsp;advertising,
              education, and&nbsp;social&nbsp;transformation.
            </h1>
            <h1 className='bigTitleOverride white-text'>PLAY.</h1>
            <div className='invaderscorecontainer' style={{ display: 'none' }}>
              <span className='invaderscoretext'>YOUR SCORE: </span>
              <span id='score_display' className='invaderscoretext'>
                0
              </span>
            </div>
            <div
              id='si1'
              className='spaceinvader'
              style={{ position: 'absolute', right: '150px', top: '180px' }}
            >
              <img
                alt='Space Invader'
                className='spaceinvaderimg'
                src={spaceInvaderImg}
              />
            </div>
            <h4 className='white-text' style={{ fontWeight: 400 }}>
              It's&nbsp;how&nbsp;we&nbsp;<b>learn</b>, how&nbsp;we&nbsp;
              <b>develop</b> relationships, and&nbsp;how&nbsp;we&nbsp;
              <b>discover</b>.
            </h4>
          </div>
        </div>
      </div>
    </Section>
  );
};
