import React from 'react';

import { Card, CardTitle, Icon, Button } from 'react-materialize';

interface Props {
  data: any;
  className?: string;
}
export const FeatureCard = (props: Props) => {
  const { data, className } = props;

  return (
    <Card
      className={`${className}`}
      closeIcon={<Icon>close</Icon>}
      header={<CardTitle image={data.image} reveal waves='light' />}
      reveal={data.full}
      revealIcon={<Icon>more_vert</Icon>}
      title={data.title}
    >
      <div
        style={{
          borderBottom: '1px solid rgba(160,160,160,0.2)',
          paddingBottom: '10px',
          marginBottom: '15px',
        }}
      >
        <p>{data.teaser}</p>
      </div>

      <p>
        <Button
          flat
          className='color-jrvgreen white-text'
          waves='light'
          onClick={() => {
            document.location.assign(data.buttonlink);
          }}
        >
          {data.buttontext}
        </Button>
      </p>
    </Card>
  );
};
