import React from 'react';
import { Footer } from 'react-materialize';
//
import '../../styles/custom-icons.css';
//
import spaceInvaderImg from '../../img/backgrounds/si_50px-social.gif';

export const FooterComponent = () => {
  const copyText = `Copyright ©1999-${Date().substr(
    11,
    4
  )} Jose R. Rodriguez / JRVisuals LLC, All rights reserved.`;
  return (
    <section id='connect'>
      <Footer
        className='blue-grey darken-1'
        style={{ marginTop: 0 }}
        copyrights={copyText}
        links={
          <ul>
            <li>
              <a
                href='https://www.linkedin.com/company/jrvisuals-llc'
                className='iconWrapper color4 tips icoLinkedin'
                title='Linkedin'
              >
                <i className='blue-grey-text text-lighten-2 small right icon-linkedin'></i>
              </a>
            </li>
            <li>
              <a
                href='https://www.facebook.com/JRVisuals'
                className='iconWrapper color4 tips icoFacebook'
                title='Facebook'
              >
                <i className='blue-grey-text text-lighten-2 small right icon-facebook'></i>
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/jrvisualsllc'
                className='iconWrapper color4 tips icoTwitter'
                title='Twitter'
              >
                <i className='blue-grey-text text-lighten-2 small right icon-twitter'></i>
              </a>
            </li>
            <li>
              <a
                href='github.com/jrod-disco'
                className='iconWrapper color4 tips icoGithub'
                title='Github'
              >
                <i className='blue-grey-text text-lighten-2 small right icon-github'></i>
              </a>
            </li>
            <li>
              <div id='si4' className='spaceinvader'>
                <img
                  alt='Space Invader'
                  className='spaceinvaderimg right'
                  style={{
                    position: 'relative',
                    height: '30px',
                    top: '5px',
                    paddingLeft: '6px',
                  }}
                  src={spaceInvaderImg}
                />
              </div>
            </li>
          </ul>
        }
      >
        <h5 className='white-text'>
          <b>Thank You.</b>
        </h5>
        <p className='grey-text text-lighten-4'>
          Feel free to reach out via social media with any inquiries.{' '}
          <em>
            Please note that I am currently working full-time at Warner Bros.
            Discovery&nbsp;Inc. and am not available for project work.
          </em>
        </p>
      </Footer>
    </section>
  );
};
