import React from 'react';
import { Section } from 'react-materialize';

export const History = () => {
  return (
    <Section
      id='history'
      className='section scrollspy background-svgcog-lite'
      style={{
        padding: '25px',
        paddingBottom: '25px',
        backgroundColor: '#301b7b',
      }}
    >
      {/* 
      <div className='section white'>
        <div className='container'>
          <div className='row'>
            <div className='col s12'>
              <h1
                className='subTitle'
                style={{ marginTop: '25px', fontWeight: 700 }}
              >
                THE&nbsp;STORY
              </h1>
              <h2 className='subTitle' style={{ marginTop: '-25px' }}>
                Coming Soon...
              </h2>
            </div>
          </div>
        </div>
        <div className='center'>
          <div className='row'>
            Timeline of the company history and personal career hilights.
          </div>
        </div>
      </div>
       */}
    </Section>
  );
};
