import React from 'react';
import { Button } from 'react-materialize';

export const IconGrid = () => {
  const displayContent = data.map((item, i) => {
    return (
      <Button
        key={item.image}
        floating
        tooltip={item.tooltip}
        tooltipOptions={{
          enterDelay: 100,
          position: 'top',
        }}
        style={{ margin: 5 }}
      >
        <img
          key={item.image}
          src={`${process.env.PUBLIC_URL}img/gameicons/${item.image}`}
          alt={item.tooltip}
        />
      </Button>
    );
  });

  return <div>{displayContent}</div>;
};

// TODO: centralize or externalize this data
const data = [
  {
    image: 'lrg_game_thumb_html_cloudfall.png',
    tooltip: 'Self Promotion - Cloudfall',
  },
  {
    image: 'lrg_game_thumb_html_prdino.png',
    tooltip: 'Power Rangers - Dino Rangers Battle',
  },
  {
    image: 'lrg_game_thumb_html_stealthmatch.png',
    tooltip: 'Watchdogs - Stealth Match',
  },
  {
    image: 'lrg_game_thumb_html_dpfootball.png',
    tooltip: 'Doctor Pepper - Quarterback Challenge',
  },
  {
    image: 'lrg_game_thumb_balloon.png',
    tooltip: "Self Promotion - Julian's Balloon",
  },
  { image: 'lrg_game_thumb_beegame.png', tooltip: 'Self Promotion - Bee Game' },
  {
    image: 'lrg_game_thumb_jrvbaseinvaders.png',
    tooltip: 'Self Promotion - Base Invader',
  },
  { image: 'lrg_game_thumb_jrvbeamer.png', tooltip: 'Self Promotion - Beamer' },
  {
    image: 'lrg_game_thumb_jrvcollector.png',
    tooltip: 'Self Promotion - Collector',
  },
  {
    image: 'lrg_game_thumb_jrvdropper.png',
    tooltip: 'Self Promotion - Dropper',
  },
  {
    image: 'lrg_game_thumb_jrvmeteors.png',
    tooltip: 'Self Promotion - Meteors',
  },
  {
    image: 'lrg_game_thumb_caxia.png',
    tooltip: 'Self Promotional - Match Caixa 2014',
  },
  {
    image: 'lrg_game_thumb_JRVpp6.png',
    tooltip: 'Self Promotion - Penguin Panic 6',
  },
  { image: 'lrg_game_thumb_jrvracing.png', tooltip: 'Self Promotion - Racing' },
  {
    image: 'lrg_game_thumb_jrvreversi.png',
    tooltip: 'Self Promotion - Reversi',
  },
  {
    image: 'lrg_game_thumb_jrvricochet.png',
    tooltip: 'Published Book - Ricochet',
  },
  {
    image: 'lrg_game_thumb_jrvsnofun.png',
    tooltip: "Self Promotion - Sn'o Fun",
  },
  {
    image: 'lrg_game_thumb_pocketmath.png',
    tooltip: 'Self Promotion - Pocket Math',
  },
  {
    image: 'lrg_game_thumb_penguinslide.png',
    tooltip: 'Self Promotion - Penguin Slide',
  },
  {
    image: 'lrg_game_thumb_ppalienadventure.png',
    tooltip: 'Self Promotion - Penguin Alien Adventure',
  },
  {
    image: 'lrg_game_thumb_ppholidayexpress.png',
    tooltip: 'Self Promotion - Penguin Holiday Express',
  },
  {
    image: 'lrg_game_thumb_ppiv.png',
    tooltip: 'Self Promotion - Penguin Panic IV',
  },
  {
    image: 'lrg_game_thumb_pppengooey.png',
    tooltip: 'Self Promotion - Pengooey',
  },
  { image: 'lrg_game_thumb_pronz.png', tooltip: 'Self Promotion - Pronz Game' },
  {
    image: 'lrg_game_thumb_quadland.png',
    tooltip: 'Self Promotion - QuadLand',
  },
  {
    image: 'lrg_game_thumb_quadra.png',
    tooltip: 'Self Promotion - QuadraPair 42',
  },
  {
    image: 'lrg_game_thumb_XMAS10_sb.png',
    tooltip: 'Self Promotion - Silver Bells',
  },

  {
    image: 'lrg_game_thumb_html_spacejumk.png',
    tooltip: 'Experimental - Space Junk',
  },
  {
    image: 'lrg_game_thumb_alife.png',
    tooltip: 'Experimental - A-Life Simulation',
  },

  {
    image: 'lrg_game_thumb_RR_mdolls.png',
    tooltip: 'Roadrunner Records - Murder Dolls',
  },
  {
    image: 'lrg_game_thumb_mosh.png',
    tooltip: 'Roadrunner Records - Photo Mosh',
  },
  {
    image: 'lrg_game_thumb_madn.png',
    tooltip: 'Roadrunner Records - Picture Madness',
  },
  {
    image: 'lrg_game_thumb_adalia.png',
    tooltip: "Roadrunner Records - Adalia's Attic",
  },

  {
    image: 'lrg_game_thumb_invasion.png',
    tooltip: 'Selatra Mobile - Invasion',
  },
  { image: 'lrg_game_thumb_reversi.png', tooltip: 'Selatra Mobile - Reversi' },
  { image: 'lrg_game_thumb_meteors.png', tooltip: 'Selatra Mobile - Meteors' },

  {
    image: 'lrg_game_thumb_trebuchet.png',
    tooltip: 'GlobalSpec - Trebuchet Challenge',
  },
  {
    image: 'lrg_game_thumb_brainstrainer.png',
    tooltip: 'GlobalSpec - Brain Strainer',
  },

  {
    image: 'lrg_game_thumb_cambria.png',
    tooltip: 'Cambria Suites - Hotel Simulator',
  },
  { image: 'lrg_game_thumb_eroom.png', tooltip: 'Ernst & Young - Invaders' },
  {
    image: 'lrg_game_thumb_itn.png',
    tooltip: 'InfoTech Niagara - Stapler Chase',
  },

  {
    image: 'lrg_game_thumb_5hrcoffee.png',
    tooltip: '5 Hour Energy Coffee Run',
  },
  { image: 'lrg_game_thumb_5hrdash.png', tooltip: '5 Hour Energy Dash' },
  {
    image: 'lrg_game_thumb_ARNOLDmhl.png',
    tooltip: "Mike's Hard Lemonade - Football Flick",
  },
  {
    image: 'lrg_game_thumb_hbglopdrop.png',
    tooltip: 'Hubba Bubba Gum - Gop Drop',
  },
  { image: 'lrg_game_thumb_eggos.png', tooltip: "Kellogg's - Eggo Maze" },
  { image: 'lrg_game_thumb_swirlz.png', tooltip: "Kellogg's - Mini Swirlz" },
  {
    image: 'lrg_game_thumb_bmg.png',
    tooltip: 'Blue Man Group - Marshmallow Catch',
  },
  { image: 'lrg_game_thumb_mmamazing.png', tooltip: 'M&M - Amazing Crispy' },
  { image: 'lrg_game_thumb_mmhopping.png', tooltip: 'M&M - Hopper' },
  {
    image: 'lrg_game_thumb_LBmemoryfreeze.png',
    tooltip: 'Popsicle - Memory Freeze',
  },
  {
    image: 'lrg_game_thumb_LBsweetsweep.png',
    tooltip: 'Good Humor - Ice Cream Truck',
  },
  {
    image: 'lrg_game_thumb_LBtreatpuzzler.png',
    tooltip: 'Good Humor - Puzzler',
  },
  { image: 'lrg_game_thumb_yogoslider.png', tooltip: 'Yogos - Slider' },
  { image: 'lrg_game_thumb_yogosroundup.png', tooltip: 'Yogos - Roundup' },
  {
    image: 'lrg_game_thumb_mightyride.png',
    tooltip: 'Mighty Bites - Mighty Ride',
  },
  {
    image: 'lrg_game_thumb_mightywake.png',
    tooltip: 'Mighty Bites - Mighty Wake',
  },
  {
    image: 'lrg_game_thumb_snackblast.png',
    tooltip: 'Mighty Bites - Snack Blast',
  },
  {
    image: 'lrg_game_thumb_chickensoccer.png',
    tooltip: 'Mighty Bites - Chicken Soccer',
  },

  {
    image: 'lrg_game_thumb_chaoticreversi.png',
    tooltip: '4KIDS - Chaotic Reversi',
  },
  { image: 'lrg_game_thumb_gicopter.png', tooltip: '4KIDS - G.I. Joe Copter' },
  {
    image: 'lrg_game_thumb_battlenexus.png',
    tooltip: '4KIDS - TMNT Battle Nexus',
  },
  { image: 'lrg_game_thumb_dig.png', tooltip: '4KIDS - Dinosaur King Dig' },
  {
    image: 'lrg_game_thumb_dojo.png',
    tooltip: '4KIDS - TMNT Mind Training Dojo',
  },
  {
    image: 'lrg_game_thumb_gunjinmeteors.png',
    tooltip: '4KIDS - TMNT Gunjin Meteors',
  },
  { image: 'lrg_game_thumb_tmntbrawl.png', tooltip: '4KIDS - TMNT Brawl' },
  {
    image: 'lrg_game_thumb_tmntcodegreen.png',
    tooltip: '4KIDS - TMNT Code Green',
  },
  {
    image: 'lrg_game_thumb_tmntvideoplay.png',
    tooltip: '4KIDS - TMNT Video Play',
  },
  { image: 'lrg_game_thumb_kirby.png', tooltip: "4KIDS - Kirby's Power Stomp" },
  { image: 'lrg_game_thumb_vivagetthere.png', tooltip: '4KIDS - Viva Pinata' },
  { image: 'lrg_game_thumb_winx.png', tooltip: '4KIDS - Winx Pixie Rescue' },
  { image: 'lrg_game_thumb_monsterjam.png', tooltip: '4KIDS - Monster Jam' },

  {
    image: 'lrg_game_thumb_fpmatching.png',
    tooltip: 'FisherPrice - Little People Matching',
  },
  {
    image: 'lrg_game_thumb_fpphoto.png',
    tooltip: 'FisherPrice - Little People Photo Hunt',
  },

  {
    image: 'lrg_game_thumb_four_in_a_row.png',
    tooltip: 'Tales4Tomorrow - Four in a Row',
  },
  {
    image: 'lrg_game_thumb_T4T_animSounds.png',
    tooltip: 'Tales4Tomorrow - Animal Sounds',
  },
  {
    image: 'lrg_game_thumb_animal_tracks.png',
    tooltip: 'Tales4Tomorrow - Animal Tracks',
  },
  {
    image: 'lrg_game_thumb_T4T_balance.png',
    tooltip: 'Tales4Tomorrow - Animal Balance',
  },
  {
    image: 'lrg_game_thumb_T4T_hopper.png',
    tooltip: 'Tales4Tomorrow - Hopper',
  },
  {
    image: 'lrg_game_thumb_T4T_lionRescue.png',
    tooltip: 'Tales4Tomorrow - Lion Cub Rescue',
  },
  {
    image: 'lrg_game_thumb_T4T_slider.png',
    tooltip: 'Tales4Tomorrow - Turtle Slide',
  },

  { image: 'lrg_game_thumb_bermudagolf.png', tooltip: 'Bermuda Travel - Golf' },

  {
    image: 'lrg_game_thumb_CANADIENSpx4.png',
    tooltip: 'Montreal Canadiens - Connect',
  },
  {
    image: 'lrg_game_thumb_CANADIENSreversi.png',
    tooltip: 'Montreal Canadiens - Reversi',
  },

  { image: 'lrg_game_thumb_dap.png', tooltip: 'Samsung - Destroy a Printer' },

  {
    image: 'lrg_game_thumb_genrx.png',
    tooltip: 'Movie Promotion - Generation RX',
  },
  {
    image: 'lrg_game_thumb_halfbaked.png',
    tooltip: 'Movie Promotion - Half Baked Maze',
  },

  {
    image: 'lrg_game_thumb_IH_healthyhoppin.png',
    tooltip: "Independent Health - Healthy Hoppin'",
  },
  {
    image: 'lrg_game_thumb_IH_picnicpark.png',
    tooltip: 'Independent Health - Picnic Park',
  },
  {
    image: 'lrg_game_thumb_IH_snackattack.png',
    tooltip: 'Independent Health - Snack Attack',
  },

  {
    image: 'lrg_game_thumb_LBbasketball.png',
    tooltip: 'Lunchbox - Basketball',
  },

  {
    image: 'lrg_game_thumb_LBobtetris.png',
    tooltip: 'OB Tampons - OB Challenge',
  },
  {
    image: 'lrg_game_thumb_LBsimplematch.png',
    tooltip: 'Lunchbox - Simple Match',
  },
  { image: 'lrg_game_thumb_LBstd.png', tooltip: 'AXE - Tower Defense' },
  {
    image: 'lrg_game_thumb_lcdwalker.png',
    tooltip: 'Self Promotional - LCD Zombie Walker',
  },

  { image: 'lrg_game_thumb_monkey.png', tooltip: 'Castrol - Motor Monkey' },

  {
    image: 'lrg_game_thumb_olympus.png',
    tooltip: 'Olympus - Stylus Maze Game',
  },
  { image: 'lrg_game_thumb_oxy.png', tooltip: 'Oxy - Maze Twisters' },
  { image: 'lrg_game_thumb_oxygenator.png', tooltip: 'Oxy - Oxygenator' },
  {
    image: 'lrg_game_thumb_paddock.png',
    tooltip: 'Paddock Chevrolet - Car Hunter',
  },

  { image: 'lrg_game_thumb_pcycling.png', tooltip: 'Pepcid - Olympic Cycling' },
  {
    image: 'lrg_game_thumb_pequestrian.png',
    tooltip: 'Pepcid - Olympic Equestrian',
  },
  { image: 'lrg_game_thumb_phurdles.png', tooltip: 'Pepcid - Olympic Hurdles' },
  {
    image: 'lrg_game_thumb_pswimming.png',
    tooltip: 'Pepcid - Olympic Swimming',
  },
  { image: 'lrg_game_thumb_pvault.png', tooltip: 'Pepcid - Olympic Vaulting' },
  {
    image: 'lrg_game_thumb_plongjump.png',
    tooltip: 'Pepcid - Olympic Long Jump',
  },

  {
    image: 'lrg_game_thumb_pinball.png',
    tooltip: 'Arizona Jeans - Pinball 2000',
  },

  {
    image: 'lrg_game_thumb_psp.png',
    tooltip: 'Retail Promotion - Poolside Pong',
  },

  {
    image: 'lrg_game_thumb_royalreversi.png',
    tooltip: 'Royal Airforce - Reversi',
  },

  {
    image: 'lrg_game_thumb_spdodgeball.png',
    tooltip: 'Comedy Central - SouthParh Dodgeball',
  },
  {
    image: 'lrg_game_thumb_stressblocker.png',
    tooltip: 'Arrid XX - Stress Blocker',
  },

  {
    image: 'lrg_game_thumb_teenhipsearch.png',
    tooltip: 'Teen Hip - Smoking PSA',
  },

  {
    image: 'lrg_game_thumb_TRINETflipfootball.png',
    tooltip: 'TriNet - Football',
  },
  {
    image: 'lrg_game_thumb_TRINETpinbowling.png',
    tooltip: 'TriNet - Pin Bowling',
  },

  { image: 'lrg_game_thumb_wad.png', tooltip: "XODDOS - Whack'a'Do" },
  { image: 'lrg_game_thumb_XODDObg.png', tooltip: 'Retail Promotion - XODDOS' },
];
